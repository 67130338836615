<template>
  <div>
    <a-form-model layout="inline">
      <a-form-model-item class="full-width flex flex-end" style="text-align: right;">
        <ImportExcel text="批量导入" @success="onSearch()"></ImportExcel>
        <a-button class="mr-30" @click="onCreate">新增</a-button>
      </a-form-model-item>
      <a-form-model-item label="姓名/电话/身份证号" class="form-item">
        <a-input style="width: 240px;" v-model="form.search" :allow-clear="true" placeholder="请输入姓名/电话/身份证号"/>
      </a-form-model-item>
      <a-form-model-item label="权限范围" class="form-item" >
        <a-select v-model="form.org_ids" :allow-clear="true" :show-search="true"
            placeholder="请选择权限范围" 
            mode="multiple"
            style="width: 240px;"
            :filter-option="filterOption" @change="select">
          <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item style="text-align: right;">
        <a-button type="primary" @click="onSearch">搜索</a-button>
        <a-button class="mr-30" @click="onReset">重置</a-button>
      </a-form-model-item>
    </a-form-model>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="user_id"
             class="mt-20 table small-cell-table">

      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="onEdit(record)">编辑</a-button>
        <a-button type="link" @click="deleteLimit(record)">删除</a-button>
      </template>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>

    <CreateLimitModal ref="createLimitModalRef" @closed="onClosed"/>
  </div>
</template>

<script>
import { getLimitUserList, getLimitList, deleteUserLimit } from './api';
import Pagination, { getPagination, updatePagination } from '@components/Pagination.vue';
import ImportExcel from '@/components/limits/ImportExcel.vue'
import CreateLimitModal from './components/CreateLimitModal.vue';

const form = {
  search: '',
  org_ids: [],
};

const columns = [
  {
    width: '100px',
    align: 'center',
    title: 'UID',
    key: 'user_id',
    dataIndex: 'user_id',
  },
  {
    width: '120px',
    align: 'center',
    title: '姓名',
    key: 'truename',
    dataIndex: 'truename',
  },
  {
    width: '120px',
    align: 'center',
    title: '电话',
    key: 'username',
    dataIndex: 'username',
  },
  {
    width: '120px',
    align: 'center',
    title: '身份证号',
    key: 'idcard',
    dataIndex: 'idcard',
  },
  {
    width: '120px',
    align: 'center',
    title: '权限范围',
    key: 'relation_org',
    dataIndex: 'relation_org',
  },
  {
    width: '120px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'List',
  components: {
    Pagination,
    CreateLimitModal,
    ImportExcel,
  },
  data() {
    return {
      loading: false,
      downloading: false,
      form: { ...form },
      columns,
      data: [],
      pagination: { ...getPagination() },
      options: [],
    };
  },
  created() {
    this.onSearch();
    this.getLimitList();
  },
  methods: {
    onReset() {
      this.form = { ...form };
      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    filterOption(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) > -1;
    },
    select() {

    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        search: this.form.search || '',
        org_ids: this.form.org_ids.join(',') || '',
        page,
        per_page: pageSize,
      };
      const data = await getLimitUserList(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = updatePagination(this.pagination, pagination);
    },
    async getLimitList() {
      const data = await getLimitList().finally(() => { });
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      this.options = result;
      result.forEach(item => {
         if(item.children){
           this.options.push(...item.children);
         }
      });
    },
    onCreate() {
      this.showCreateLimitModal({});
    },
    onEdit(record) {
      this.showCreateLimitModal(record);
    },
    deleteLimit(record) {
      this.$confirm({
        content: () => <div>确认删除？</div>,
        onOk: () => this.deleteLimitWithConfirm(record),
      });
    },
    async deleteLimitWithConfirm(record) { 
       if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await deleteUserLimit(record.user_id).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        this.loading = false;
        return;
      }
      this.$message.success(data.message || '删除成功');
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    showCreateLimitModal(record) {
      if (this.$refs.createLimitModalRef?.show) {
        this.$refs.createLimitModalRef.show({
          ...(record || {}),
          user_id: record.user_id,
          options: this.options,
        });
      }
    },
    onClosed(evt) {
      if (evt?.all) {
        this.onSearch();
      } else if (evt?.item) {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-item {
  margin-bottom: 5px;
  ::v-deep .input {
    min-width: 240px !important;
  }
}
</style>
